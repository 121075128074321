import styled from '@emotion/styled'
import { graphql } from 'gatsby'
import React, { useEffect, useState } from 'react'

import queryString from 'query-string'
import PersonliseModal from '../components/Personalisation'
import PanelRenderer from '../components/panels/panel-renderer'
import Reviews from '../components/product-page/reviews'
import GallerySectionV3 from '../components/product-page/v3/gallery-section'
import GallerySectionV2 from '../components/product-page/v2/gallery-section'

import SelctorSection from '../components/product-page/v3/selector-section'
import SEO from '../components/seo/index'
import { useShopStoreIncontext } from '../context/shopContext'
import calculateDiscount from '../helpers/calculateDiscount'
import useTracking from '../helpers/useTracking'
import useIsInView from '../hooks/useIsInView'
import useSelectedColor from '../hooks/useSelectedColor'
import '../styles/reviews.css'
import ProductDetailTiles from '../components/panelsV2/ProductFeatureTiles'

const LandingContainer = styled.div`
  min-height: 100vh;
  height: auto;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  justify-content: space-around;
  @media (min-width: 763px) {
    flex-direction: row;
    /* padding-top: 64px; */
  }
`

function retryLearnQ(query, timout) {
  try {
    query()
  } catch (err) {
    if (!window.location.href.includes('uk')) {
      setTimeout(() => retryLearnQ(query, timout), timout)
    }
  }
}
function isEmpty(obj) {
  for (var key in obj) {
    if (obj.hasOwnProperty(key)) return false
  }
  return true
}
function hexToRgb(hex) {
  try {
    if (!hex) return [0, 0, 0]
    const bigint = parseInt(hex.substring(1), 16);
    const r = (bigint >> 16) & 255;
    const g = (bigint >> 8) & 255;
    const b = bigint & 255;

    return [r, g, b];
  } catch (e) {
    return [0, 0, 0]
  }
}
function rgbToHsv(r, g, b) {
  let max = Math.max(r, g, b), min = Math.min(r, g, b);
  let h, s, v = max;

  let d = max - min;
  s = max === 0 ? 0 : d / max;

  if (max === min) {
    h = 0; // achromatic
  } else {
    switch (max) {
      case r: h = (g - b) / d + (g < b ? 6 : 0); break;
      case g: h = (b - r) / d + 2; break;
      case b: h = (r - g) / d + 4; break;
    }
    h /= 6;
  }

  return [h, s, v];
}
const getShopifySkuData = (products) => {
  let allData = {}
  products.Variants.map(
    (variant) => {
      const metaId = `shopify_${products?.shopifyInfo?.shopifyId?.replace('gid://shopify/Product/', '')}_${variant?.shopifyInfo?.shopifyId?.replace('gid://shopify/ProductVariant/', '')}`
      allData[variant?.shopifyInfo?.sku] = {
        id: variant?.shopifyInfo?.shopifyId,
        sku: variant?.shopifyInfo?.sku,
        image: variant?.shopifyInfo?.image,
        price: variant?.shopifyInfo?.price,
        title: variant?.shopifyInfo?.title,
        meta_id: metaId?.includes('undefined') ? undefined : metaId,
        productId: products?.shopifyInfo?.shopifyId?.replace('gid://shopify/Product/', ''),
        product_categories: products?.product_categories,
      }
      if (variant?.shopifyInfoPers) {
        allData[variant?.shopifyInfoPers?.sku] = {
          id: variant?.shopifyInfoPers?.shopifyId,
          sku: variant?.shopifyInfoPers?.sku,
          image: variant?.shopifyInfoPers?.image,
          price: variant?.shopifyInfoPers?.price,
          title: variant?.shopifyInfoPers?.title,
          productId: products?.shopifyInfo?.shopifyId?.replace('gid://shopify/Product/', ''),
          product_categories: products?.product_categories,
        }
      }
    }
  )

  return allData
}
const useShopifyData = (localeData, locale) => {
  const [skuData] = useState(getShopifySkuData(localeData))
  return {
    [locale]: {
      skuData,
      productId: localeData?.shopifyInfo?.shopifyId.replace('gid://shopify/Product/', ''),
      tolstoyId: localeData?.shopifyInfo?.storefrontId.replace('gid://shopify/Product/', ''),
      reviewData: localeData?.shopifyInfo?.reviewData
    },
  }
}
const useProduct = (strapiProduct, rawStock) => {
  let product = {
    ...strapiProduct,
    product_categories: strapiProduct.product_categories?.map((val) => val.CategoryName),
  }

  let stock = JSON.parse(rawStock.skus)

  if (!product) return {}
  const { stock: contextStock, storeLocale } = useShopStoreIncontext(
    (state) => {
      return {
        stock: state.stock,
        storeLocale: state.storeLocale,
      }
    }
  )
  if (contextStock[storeLocale] != null) {
    stock = contextStock[storeLocale]
  }
  product.Variants = product.Variants.map((variant) => ({
    ...variant,
    oosData: variant.SKU.includes('giftcard') ? {} : { ...stock[variant.SKU] },
    product_categories: strapiProduct.product_categories?.map((val) => val.CategoryName),
  }))

  // Upsell producst variants oosData
  if (strapiProduct?.Upsell?.Product?.Variants !== null && strapiProduct?.Upsell?.Product?.Variants !== undefined) {
    strapiProduct.Upsell.Product.Variants = strapiProduct.Upsell.Product.Variants.map((variant) => ({
      ...variant,
      oosData: variant.SKU.includes('giftcard') ? {} : { ...stock[variant.SKU] },
    }))
  }

  return {
    product,
    stock,
  }
}
function formatDecimal(number) {
  if (Number.isInteger(number)) {
    return number?.toFixed(0); // No decimal places
  } else {
    return number?.toFixed(1); // One decimal place
  }
}

const ArticlePage = ({ pageContext, data, location }) => {
  const { FreePersonalisation, FreeSuitcasePersonalisation, OosSignupID, PetPersonalisation, hideBundlePricing, disablePersonalisation, disableEmbroidery, discountPromoOn } = data.strapiShared
  const [labelData, setLabelData] = useState({})
  const [petLabelData, setPetLabelData] = useState({})
  const allProductData = useShopifyData(data.strapiProduct, pageContext.locale)
  const { [pageContext.locale]: { skuData, productId, tolstoyId, reviewData } } = allProductData
  const {
    storeLocale,
    currencyCode,
    setCollectionOpen,
    footerRef,
    setPauseScroll,
    addToCart,
    analytics,
  } = useShopStoreIncontext((state) => {
    return {
      storeLocale: state.storeLocale,
      currencyCode: state.currencyCode,
      setCollectionOpen: state.actions.setCollectionOpen,
      footerRef: state.footerRef,
      setPauseScroll: state.actions.setPauseScroll,
      addToCart: state.actions.addToCart,
      analytics: state.analytics
    }
  })
  const { product, stock } = useProduct(data.strapiProduct, data.stock)

  const additionalVariants = [
    {
      id: product.strapiId,
      variants: product.Variants
    }
  ]
  const additionalProducts = data?.additionalStrapiProducts?.nodes
  additionalProducts?.map(product => {
    let variants = []
    product.Variants.map(variant => {
      variants.push({
        handle: product.handle,
        product_categories: product.product_categories,
        ...variant,
        oosData: {
          ...stock[variant.SKU],
        },
      })
    })
    additionalVariants.push({
      id: product.strapiId,
      variants: variants
    })
  })
  let allVariants =
    additionalVariants
      .sort((a, b) => a.id - b.id)
      .map(val => val.variants)
      .flat()
  // .sort((a, b) => {
  //   const [hA] = rgbToHsv(...hexToRgb(a.Colour));
  //   const [hB] = rgbToHsv(...hexToRgb(b.Colour));

  //   // Adjust the hue values to start from red and wrap around the circle
  //   const adjustedHA = (hA + 0.93) % 1;
  //   const adjustedHB = (hB + 0.93) % 1;

  //   return adjustedHA - adjustedHB;
  // });

  // Condition to skip sorting for Tunks to prevent new colours being shuffled with the rest of the colours
  if (!allVariants[0].SKU.includes('TRK')) {
    allVariants
      .sort((a, b) => {
        if (a.limited === true || b.limited === true) {
          return
        }
        const [hA] = rgbToHsv(...hexToRgb(a.Colour));
        const [hB] = rgbToHsv(...hexToRgb(b.Colour));

        // Adjust the hue values to start from red and wrap around the circle
        const adjustedHA = (hA + 0.93) % 1;
        const adjustedHB = (hB + 0.93) % 1;

        return adjustedHA - adjustedHB;
      });
  }

  const colorNames = allVariants.map((variant) =>
    variant.ColourName.toLowerCase().split(' ').join('-').replace('/', 'slash')
  )
  const ossData = allVariants.map((variant) => ({
    ...skuData[variant.SKU],
    ...stock[variant.SKU],
    sku: variant.SKU,
  }))

  const [selectedColor, setSelectedColor] = useSelectedColor(
    location.search,
    0,
    colorNames,
    ossData
  )

  const [personaliseOpen, setPersonaliseOpen] = useState(Object.keys(queryString.parse(location.search)).includes('personalise'))
  useEffect(() => {
    setPauseScroll(personaliseOpen)
  }, [personaliseOpen])
  const [petPersonaliseOpen, setPetPersonalisationOpen] = useState(undefined)
  const [selectedDropdownItem, setSelectedDropdownItem] = useState(product?.Dropdown?.findIndex((val) => val.to.includes(product.handle)))
  const selectedVariant = allVariants[selectedColor]
  const personalised = isEmpty(labelData)
  const petPersonalised = isEmpty(petLabelData)
  const selectedSKU = `${selectedVariant?.SKU}${personalised && petPersonalised ? '' : '-P'}`
  const product_categories = product.product_categories
  const isLuggage = product.product_categories?.includes("Luggage")
  const isBag = product.product_categories?.includes("Bags", "Soft Good (Excluding Weekenders)", "Weekender")

  const persData = persLookup[selectedVariant?.SKU]
  // Personalisation pricing calculation
  const freePers = FreePersonalisation || product.Personalisation[0]?.FreePersonalisation;
  const freeLuggagePers = FreeSuitcasePersonalisation && isLuggage;
  const addPersPrice = !freePers && !freeLuggagePers && !(personalised && petPersonalised);
  const personalisationCost = addPersPrice ? product.Personalisation[0]?.PersonalisationCost || 0 : 0;

  // Pricing calculation
  const price = (selectedVariant?.shopifyInfo?.price || 0) + personalisationCost;
  const defaultDiscount = hideBundlePricing ? 0 : (product?.Discount ? product?.Discount : 0)
  const discount = calculateDiscount(discountPromoOn, selectedVariant, defaultDiscount, price, storeLocale);

  const noSet = (product.hideSetModule === true) || !['Bag', 'Carry_On', 'Checked', 'Plus'].includes(product.BundleType)
  const images = selectedVariant.images?.map((image) => image) || []
  const thumbnails = selectedVariant.thumbnails?.map((image) => image) || []

  useEffect(() => {
    localStorage?.setItem('fbid', skuData[selectedSKU]?.meta_id)
  }, [selectedSKU, skuData])

  const [trackAddToCart, updateItem] = useTracking(
    {
      title: product.Title,
      id: skuData[selectedSKU]?.meta_id,
      imageURL: selectedVariant?.ShopTileImage?.url,
      url: `https://july.com${product.handle}?${selectedVariant.ColourName}`,
      price: price,
      sku: selectedSKU,
      currency: currencyCode,
      product_categories: product_categories,
      selectedVariant: selectedVariant,
      variant: selectedVariant.ColourName,
  },
    analytics
  )

  useEffect(() => {
    // Only call updateItem if any of the values are different from current
    updateItem((prevData) => {
      const newData = {
        title: product.Title,
        id: skuData[selectedSKU]?.meta_id,
        imageURL: selectedVariant?.ShopTileImage?.url,
        url: `https://july.com${product.handle}?${selectedVariant.ColourName}`,
        price: price,
        sku: selectedSKU,
        currency: currencyCode,
        product_categories: product_categories,
        selectedVariant: selectedVariant,
        variant: selectedVariant.ColourName,
      }
      
      // Only update if something changed
      if (JSON.stringify(prevData) !== JSON.stringify(newData)) {
        return newData
      }
      return prevData
    })
  }, [selectedColor])

  const handleAddToCart = async () => {
    addToCart({
      title: product.Title,
      ...(personalised ? {} : { customAttributes: labelData }),
      ...(petPersonalised ? {} : { customAttributes: petLabelData }),
      variantQuantity: 1,
      id: selectedSKU,
      product_categories: product_categories,
      globalProduct: { [storeLocale]: allProductData[storeLocale]['skuData'][selectedSKU] },
      currency: currencyCode,
      variant: selectedVariant.ColourName,
      ...((personalised && selectedVariant.oosData.personalisation === true && !disablePersonalisation && (['en', 'en-CA'].includes(storeLocale) ? !isBag : true)) ? {
        personalisedVariant: { [storeLocale]: allProductData[storeLocale]['skuData'][`${selectedSKU}-P`] },
      } : {}),
    })
    trackAddToCart()
  }

  const returnAddToCart = (addToCartData) => {
    addToCart([
      {
        title: product.Title,
        ...(personalised ? {} : { customAttributes: labelData }),
        ...(petPersonalised ? {} : { customAttributes: petLabelData }),
        variantQuantity: 1,
        id: selectedSKU,
        product_categories: product_categories,
        globalProduct: { [storeLocale]: allProductData[storeLocale]['skuData'][selectedSKU] },
        currency: currencyCode,
        variant: selectedVariant.ColourName,
      },
      addToCartData
    ])
  }

  const {
    inViewRef,
    isInView
  } = useIsInView(footerRef)

  const GallerySection = product["GalleryType"] ===  "Scroll" ? GallerySectionV3 : GallerySectionV2

  return (
    <>
      <SEO
        title={product.seo.metaTitle}
        description={product.seo.metaDescription}
        locale={pageContext.locale}
        cannonical_url={`${product.handle}`}
        keywords={product.seo?.keywords}
        og_image={product.seo?.metaImage?.file?.childImageSharp?.gatsbyImageData?.images?.fallback?.src}
        noCrawl={product.seo?.doNotIndex}

        productData={{
          name: product.Title,
          imageSrc: images[0]?.file?.publicURL,
          description: product.seo.metaDescription,
          price: price,
          sku: selectedSKU,
          handle: product.seo.canonicalURL,
        }}
        reviewData={{
          value: `${formatDecimal(reviewData?.aggregateRating)}`,
          count: `${reviewData?.reviewCount}`,
        }}
      />
      <div
        style={{
          display: personaliseOpen ? 'block' : 'none',
          height: '100vh',
        }}
      >
        {personaliseOpen && (
          <div
            style={{
              position: 'fixed',
              top: 0,
              left: 0,
              bottom: 0,
              right: 0,
              zIndex: 9999999999,
            }}
          >
            <PersonliseModal
              handleSave={(data) => {
                setPersonaliseOpen(false)
                setLabelData(data)
                var _learnq = window._learnq || []
                retryLearnQ(() => {
                  _learnq.push([
                    'track',
                    'Added Personalisation',
                    {
                      ...data,
                      url: window.location.href,
                      sku: selectedVariant?.SKU,
                    },
                  ])
                }, 3000)
              }}
              handleClose={() => setPersonaliseOpen(false)}
              initialValues={labelData}
              selectedCaseColor={persData.color_id}
              selectedCase={persData.product}
              timeframe={storeLocale === 'en-GB' ? '7' : storeLocale === 'en' ? '5-7' : '3-7'}
            />
          </div>
        )}
      </div>
      <LandingContainer>
        <GallerySection
          images={images}
          thumbnails={thumbnails}
          selectedColor={selectedColor}
          pdpRibbon={data.strapiShared?.GalleryRibbon}
          product_categories={product?.product_categories}
          discount={discount}
          defaultImage={selectedVariant?.onChangeImageIndex ? selectedVariant?.onChangeImageIndex : 0}
          disablePersonalisation={data.strapiShared?.disablePersonalisation}
          disableEmbroidery={data.strapiShared?.disableEmbroidery}
          locale={storeLocale}
          discountPromoOn={discountPromoOn}
          productId={productId}
          showBuildSet={['Carry_On', 'Checked', 'Plus'].includes(product?.BundleType)}
          hideRibbon={selectedSKU === 'AOC-PROV2-COPB28-CRM'}
        />
        <SelctorSection
          inViewRef={inViewRef}
          discountPromoOn={discountPromoOn}
          reviewData={reviewData}
          isInView={isInView}
          upsell={product?.Upsell}
          comparison={product.Comparison}
          returnAddToCart={returnAddToCart}
          name={product.Title}
          subHeading={product.SubHeadingText}
          price={price}
          outline={product.Description}
          selectedColor={selectedColor}
          setSelectedColor={setSelectedColor}
          handleAddToCart={handleAddToCart}
          reviewId={productId}
          tolstoyId={tolstoyId}
          variants={allVariants}
          expandSections={product.ExpandSections}
          hasPersonlisation={product.Personalisation[0] != undefined}
          personalisationCost={product.Personalisation[0]?.PersonalisationCost}
          freePersonalisation={FreePersonalisation ? FreePersonalisation : product.Personalisation[0]?.FreePersonalisation}
          freeSuitcasePersonalisation={FreeSuitcasePersonalisation}
          discount={discount}
          shortName={product.ShortName}
          personalised={personalised}
          petPersonalised={petPersonalised}
          labelData={labelData}
          petLabelData={petLabelData}
          setPersonaliseOpen={setPersonaliseOpen}
          setPetPersonalisationOpen={setPetPersonalisationOpen}
          selectedVariant={selectedVariant}
          noSet={noSet}
          setCollectionOpen={setCollectionOpen}
          signup_id={product.ProductSignUpId ? product.ProductSignUpId : OosSignupID}
          useDropdown={product.useDropdown}
          AirlineReq={product.AirlineReq}
          product_categories={product.product_categories}
          dropdownData={product.Dropdown}
          selectedDropdownItem={selectedDropdownItem}
          setSelectedDropdownItem={setSelectedDropdownItem}
          aboveColorSelector={product.ToggleItems}
          useToggleSelector={product.UseToggleSelector}
          url={product.handle}
          PetPersonalisation={PetPersonalisation}
          hideBundlePricing={hideBundlePricing || false}
          disablePersonalisation={disablePersonalisation || false}
          disableEmbroidery={disableEmbroidery || false}
          isBag={isBag || false}
          productId={product?.shopifyInfo?.shopifyId?.replace('gid://shopify/Product/', '')}
          stickySelector={product["GalleryType"] ===  "Scroll"}
        />
      </LandingContainer>
      {product.Panels && <PanelRenderer blocks={product.Panels} locale={pageContext.locale} />}
      {productId && reviewData.aggregateRating > 0  && <Reviews productId={productId} />}
    </>
  )
}

// Look into moving from variants to Variant instead
export const pageQuery = graphql`
  query ($handle: String, $locale: String, $additionalStrapiProducts: [String]) {
    stock(locale: { eq: $locale }) {
      skus
    }
    strapiShared(locale: { eq: $locale }) {
      hideBundlePricing
      disablePersonalisation
      disableEmbroidery
      GalleryRibbon {
        RibbonText
        RibbonColour
        RibbonTextColour
        productPageOn
        NonPersRibbon
      }
      locale
      OosSignupID
      FreePersonalisation
      FreeSuitcasePersonalisation
      PetPersonalisation
      discountPromoOn
    }
    additionalStrapiProducts: allStrapiProduct(filter: { id: { in: $additionalStrapiProducts } }) {
      nodes {
        id
        strapiId
        handle
        product_categories {
          CategoryName
        }
        Variants {
          SKU
          Colour
          ColourName
          limited
          seperationText
          onChangeImageIndex
          SignUpID
          images {
            mime
            alternativeText
            name
            file {
              childImageSharp {
                gatsbyImageData(width: 1000, placeholder: NONE, quality: 80)
                gatsbyImageDataMobile: gatsbyImageData(quality: 70, placeholder: NONE, width: 550)
              }
              publicURL
            }
            url
          }
          ShopTileImage {
            ...Image
          }
          Edition

          thumbnails: images {
            mime
            alternativeText
            name
            file {
              childImageSharp {
                gatsbyImageData(width: 48, placeholder: NONE)
              }
              publicURL
            }
            url
          }
        }
      }
    }
    strapiProduct(handle: { eq: $handle }, locale: { eq: $locale }) {
      id
      GalleryType
      strapiId
      locale
      Title
      BundleType
      handle
      useDropdown
      ProductSignUpId
      Discount
      hideSetModule
      ShortName
      BundleRange
      BundleType
      SubHeadingText
      Dropdown {
        DropdownItem
        to
      }
      Comparison {
        Collection
        link
      }
      Upsell {
        Discount
        Heading
        Product {
          product_categories {
            CategoryName
          }
          shopifyInfo {
            ... on AUShopifyProduct {
              shopifyId
            }
            ... on USShopifyProduct {
              shopifyId
            }
            ... on CAShopifyProduct {
              shopifyId
            }
            ... on NZShopifyProduct {
              shopifyId
            }
            ... on UKShopifyProduct {
              shopifyId
            }
          }
          Variants {
            Colour
            TwoWide
            ColourName
            SKU
            SignUpID
            ShopTileImage {
              ...Image
            }
            Edition
            metaShopifyInfo {
              ... on AUShopifyProductVariant {
                product {
                  shopifyId
                }
                shopifyId
              }
            }
            shopifyInfo {
              ... on AUShopifyProductVariant {
                shopifyId
                price
                image {
                  gatsbyImageData(width: 200)
                }
              }
              ... on USShopifyProductVariant {
                shopifyId
                price
                image {
                  gatsbyImageData(width: 200)
                }
              }
              ... on CAShopifyProductVariant {
                shopifyId
                price
                image {
                  gatsbyImageData(width: 200)
                }
              }
              ... on NZShopifyProductVariant {
                shopifyId
                price
                image {
                  gatsbyImageData(width: 200)
                }
              }
              ... on UKShopifyProductVariant {
                shopifyId
                price
                image {
                  gatsbyImageData(width: 200)
                }
              }
            }
            shopifyInfoPers {
              ... on UKShopifyProductVariant {
                shopifyId
                price
                image {
                  gatsbyImageData(width: 200)
                }
              }
              ... on NZShopifyProductVariant {
                shopifyId
                price
                image {
                  gatsbyImageData(width: 200)
                }
              }
              ... on USShopifyProductVariant {
                shopifyId
                price
                image {
                  gatsbyImageData(width: 200)
                }
              }
              ... on CAShopifyProductVariant {
                shopifyId
                price
                image {
                  gatsbyImageData(width: 200)
                }
              }
              ... on AUShopifyProductVariant {
                shopifyId
                price
                image {
                  gatsbyImageData(width: 200)
                }
              }
            }
          }
          handle
          Title
        }
        Subheading
      }
      Personalisation {
        PersonalisationCost
        FreePersonalisation
      }
      product_categories {
        CategoryName
      }
      shopifyInfo {
        ... on AUShopifyProduct {
          shopifyId
          storefrontId
          reviewData {
                aggregateRating
                shopifyId
                __typename
                reviewCount
              }

        }
        ... on USShopifyProduct {
          shopifyId
          storefrontId
          reviewData {
                aggregateRating
                shopifyId
                __typename
                reviewCount
              }

        }
        ... on CAShopifyProduct {
          shopifyId
          storefrontId
          reviewData {
                aggregateRating
                shopifyId
                __typename
                reviewCount
              }
        }
        ... on UKShopifyProduct {
          shopifyId
          storefrontId
          reviewData {
                aggregateRating
                shopifyId
                __typename
                reviewCount
              }

        }
        ... on NZShopifyProduct {
          shopifyId
          storefrontId
          reviewData {
                aggregateRating
                shopifyId
                __typename
                reviewCount
              }

        }
      }
      Variants {
        SignUpID
        metaShopifyInfo {
          ... on AUShopifyProductVariant {
            product {
              shopifyId
            }
            shopifyId
          }
        }
        shopifyInfo {
          ... on AUShopifyProductVariant {
            shopifyId
            sku
            title
            price
            image {
              gatsbyImageData(width: 200)
            }
          }
          ... on USShopifyProductVariant {
            shopifyId
            sku
            title
            price
            image {
              gatsbyImageData(width: 200)
            }
          }
          ... on CAShopifyProductVariant {
            shopifyId
            sku
            title
            price
            image {
              gatsbyImageData(width: 200)
            }
          }
          ... on UKShopifyProductVariant {
            shopifyId
            sku
            title
            price
            image {
              gatsbyImageData(width: 200)
            }
          }
          ... on NZShopifyProductVariant {
            shopifyId
            sku
            title
            price
            image {
              gatsbyImageData(width: 200)
            }
          }
        }
        shopifyInfoPers {
          ... on AUShopifyProductVariant {
            shopifyId
            sku
            title
            price
            image {
              gatsbyImageData(width: 200)
            }
          }
          ... on USShopifyProductVariant {
            shopifyId
            sku
            title
            price
            image {
              gatsbyImageData(width: 200)
            }
          }
          ... on CAShopifyProductVariant {
            shopifyId
            sku
            title
            price
            image {
              gatsbyImageData(width: 200)
            }
          }
          ... on UKShopifyProductVariant {
            shopifyId
            sku
            title
            price
            image {
              gatsbyImageData(width: 200)
            }
          }
          ... on NZShopifyProductVariant {
            shopifyId
            sku
            title
            price
            image {
              gatsbyImageData(width: 200)
            }
          }
        }
        SKU
        Colour
        ColourName
        onChangeImageIndex
        limited
        images {
          mime
          alternativeText
          name
          file {
            childImageSharp {
              gatsbyImageData(width: 1000, placeholder: NONE, quality: 80)
              gatsbyImageDataMobile: gatsbyImageData(quality: 70, placeholder: NONE, width: 550)
            }
            publicURL
          }
          url
        }
        ShopTileImage {
          ...Image
        }
        Edition

        thumbnails: images {
          mime
          alternativeText
          name
          file {
            childImageSharp {
              gatsbyImageData(width: 48, placeholder: NONE)
            }
            publicURL
          }
          url
        }
      }
      Description
      ExpandSections {
        Title
        Body
      }
      Panels {
        ...RecursivePanels
      }
      ToggleItems {
        ProductTitle
        to
        FeatureItems {
          FeatureDescription
          FeatureImage {
            ...Image
          }
        }
      }
      UseToggleSelector
      seo {
        canonicalURL
        metaDescription
        metaTitle
        keywords
        doNotIndex
        metaImage {
          file {
            childImageSharp {
              gatsbyImageData(width: 1200, placeholder: NONE, formats: [JPG])
            }
          }
        }
        metaSocial {
          image {
            file {
              childImageSharp {
                fixed(width: 1200) {
                  srcWebp
                }
              }
            }
          }
          socialNetwork
          title
          description
        }
      }
      AirlineReq {
        SizeCompatibility {
          AirlineName
          Compatible
        }
      }
    }
  }
`
export default ArticlePage

export const persLookup = {
  'COPB01-NAV': { product: 'Carry', color_id: 0 },
  'COPB02-CHA': { product: 'Carry', color_id: 1 },
  'COPB03-FOR': { product: 'Carry', color_id: 2 },
  'COPB04-NUD': { product: 'Carry', color_id: 3 },
  'COPB05-BUR': { product: 'Carry', color_id: 4 },
  'COPB06-MON': { product: 'Carry', color_id: 5 },
  'COPB07-CLA': { product: 'Carry', color_id: 6 },
  'COPB07-CLY': { product: 'Carry', color_id: 6 },
  'COPB08-SKY': { product: 'Carry', color_id: 7 },
  'COPB09-SHA': { product: 'Carry', color_id: 8 },
  'COPB09-SHW': { product: 'Carry', color_id: 8 },
  'COPB10-GRY': { product: 'Carry', color_id: 9 },
  'COPB11-OLI': { product: 'Carry', color_id: 10 },
  'COPB12-PIN': { product: 'Carry', color_id: 11 },
  'COPB13-YEL': { product: 'Carry', color_id: 12 },
  'COPB14-BLU': { product: 'Carry', color_id: 13 },
  'COPB15-MAG': { product: 'Carry', color_id: 14 },
  'COPB19-CHA-RED': { product: 'Carry', color_id: 15 },
  'COPB20-CHA-BLU': { product: 'Carry', color_id: 16 },
  'COPB21-CHA-YEL': { product: 'Carry', color_id: 17 },
  'COPB16-GRN': { product: 'Carry', color_id: 18 },
  'COPB17-RED': { product: 'Carry', color_id: 19 },
  'COPB18-DKB': { product: 'Carry', color_id: 20 },
  'UP-COPB30-NAV': { product: 'CarryUpside', color_id: 21 },
  'COPB23-ORA': { product: 'Carry', color_id: 22 },
  'COPB24-DBRO': { product: 'Carry', color_id: 24 },
  'COPB25-LTAU': { product: 'Carry', color_id: 25 },
  'COPB26-SBLU': { product: 'Carry', color_id: 26 },
  'COPB27-BONE': { product: 'Carry', color_id: 27 },

  'COES21-NAV': { product: 'CarryEssential', color_id: 0 },
  'COES22-CHA': { product: 'CarryEssential', color_id: 1 },
  'COES23-FOR': { product: 'CarryEssential', color_id: 2 },
  'COES24-SAN': { product: 'CarryEssential', color_id: 3 },
  'COES25-CLA': { product: 'CarryEssential', color_id: 4 },
  'COES26-SHA': { product: 'CarryEssential', color_id: 5 },
  'COES27-YEL': { product: 'CarryEssential', color_id: 6 },
  'COES28-OLI': { product: 'CarryEssential', color_id: 7 },
  'COES29-BLU': { product: 'CarryEssential', color_id: 8 },

  'CHK01-NAV': { product: 'Checked', color_id: 0 },
  'CHK02-CHA': { product: 'Checked', color_id: 1 },
  'CHK03-FOR': { product: 'Checked', color_id: 2 },
  'CHK04-NUD': { product: 'Checked', color_id: 3 },
  'CHK05-BUR': { product: 'Checked', color_id: 4 },
  'CHK06-MON': { product: 'Checked', color_id: 5 },
  'CHK07-CLA': { product: 'Checked', color_id: 6 },
  'CHK07-CLY': { product: 'Checked', color_id: 6 },
  'CHK08-SKY': { product: 'Checked', color_id: 7 },
  'CHK09-SHA': { product: 'Checked', color_id: 8 },
  'CHK09-SHW': { product: 'Checked', color_id: 8 },
  'CHK10-GRY': { product: 'Checked', color_id: 9 },
  'CHK11-OLI': { product: 'Checked', color_id: 10 },
  'CHK12-PIN': { product: 'Checked', color_id: 11 },
  'CHK13-YEL': { product: 'Checked', color_id: 12 },
  'CHK14-BLU': { product: 'Checked', color_id: 13 },
  'CHK15-MAG': { product: 'Checked', color_id: 14 },

  'CHK19-CHA-RED': { product: 'Checked', color_id: 15 },
  'CHK20-CHA-BLU': { product: 'Checked', color_id: 16 },
  'CHK21-CHA-YEL': { product: 'Checked', color_id: 17 },
  'CHK16-GRN': { product: 'Checked', color_id: 18 },
  'CHK17-RED': { product: 'Checked', color_id: 19 },
  'CHK18-DKB': { product: 'Checked', color_id: 20 },
  'CHK23-ORA': { product: 'Checked', color_id: 21 },
  'CHK22-BPK': { product: 'Checked', color_id: 22 },
  'CHK24-SBLU': { product: 'Checked', color_id: 23 },
  'CHK25-BONE': { product: 'Checked', color_id: 24 },

  'EXP-CHK31-CHA': { product: 'CheckedExpandable', color_id: 0 },
  'EXP-CHK32-FOR': { product: 'CheckedExpandable', color_id: 1 },
  'EXP-CHK33-OLI': { product: 'CheckedExpandable', color_id: 2 },
  'EXP-CHK34-NAV': { product: 'CheckedExpandable', color_id: 3 },
  'EXP-CHK35-CLY': { product: 'CheckedExpandable', color_id: 4 },
  'EXP-CHK36-SHW': { product: 'CheckedExpandable', color_id: 5 },
  'EXP-CHK37-SAN': { product: 'CheckedExpandable', color_id: 6 },
  'EXP-CHK38-DBRO': { product: 'CheckedExpandable', color_id: 7 },
  'EXP-CHK39-LTAU': { product: 'CheckedExpandable', color_id: 8 },

  'EXP-CHK40-BPK': { product: 'CheckedExpandable', color_id: 9 },
  'EXP-PLS50-BPK': { product: 'PlusExpandable', color_id: 9 },
  'COPB22-BPK': { product: 'Carry', color_id: 28 },
  'LGT-COEX32-BPK': { product: 'LightExpandable', color_id: 10 },

  'SET-EXP-CHKCOPB60-BPK': { product: 'CheckedExpandable', color_id: 9 },
  'SET-EXP-PLSCOPB68-BPK': { product: 'PlusExpandable', color_id: 9 },
  'SET-EXP-FMLY80-BPK': { product: 'PlusExpandable', color_id: 9 },
  'SET-EXPCHK-COMAX45-BPK': { product: 'CheckedExpandable', color_id: 9 },
  'SET-EXPPLS-COMAX55-BPK': { product: 'PlusExpandable', color_id: 9 },
  'SET-EXPFMLY-COMAX55-BPK': { product: 'PlusExpandable', color_id: 9 },

  'PLS01-NAV': { product: 'Plus', color_id: 0 },
  'PLS02-CHA': { product: 'Plus', color_id: 1 },
  'PLS03-FOR': { product: 'Plus', color_id: 2 },
  'PLS04-NUD': { product: 'Plus', color_id: 3 },
  'PLS05-BUR': { product: 'Plus', color_id: 4 },
  'PLS06-MON': { product: 'Plus', color_id: 5 },
  'PLS07-CLA': { product: 'Plus', color_id: 6 },
  'PLS07-CLY': { product: 'Plus', color_id: 6 },
  'PLS08-SKY': { product: 'Plus', color_id: 7 },
  'PLS09-SHA': { product: 'Plus', color_id: 8 },
  'PLS09-SHW': { product: 'Plus', color_id: 8 },
  'PLS11-OLI': { product: 'Plus', color_id: 11 },
  'PLS13-YEL': { product: 'Plus', color_id: 12 },
  'PLS14-BLU': { product: 'Plus', color_id: 13 },
  'PLS15-MAG': { product: 'Plus', color_id: 14 },

  'PLS19-CHA-RED': { product: 'Plus', color_id: 15 },
  'PLS20-CHA-BLU': { product: 'Plus', color_id: 16 },
  'PLS21-CHA-YEL': { product: 'Plus', color_id: 17 },
  'PLS16-GRN': { product: 'Plus', color_id: 18 },
  'PLS17-RED': { product: 'Plus', color_id: 19 },
  'PLS18-DKB': { product: 'Plus', color_id: 20 },
  'PLS23-ORA': { product: 'Plus', color_id: 21 },
  'PLS22-BPK': { product: 'Plus', color_id: 22 },
  'PLS24-SBLU': { product: 'Plus', color_id: 23 },
  'PLS25-BONE': { product: 'Plus', color_id: 24 },

  'EXP-PLS41-CHA': { product: 'PlusExpandable', color_id: 0 },
  'EXP-PLS42-FOR': { product: 'PlusExpandable', color_id: 1 },
  'EXP-PLS43-OLI': { product: 'PlusExpandable', color_id: 2 },
  'EXP-PLS44-NAV': { product: 'PlusExpandable', color_id: 3 },
  'EXP-PLS45-CLY': { product: 'PlusExpandable', color_id: 4 },
  'EXP-PLS46-SHW': { product: 'PlusExpandable', color_id: 5 },
  'EXP-PLS47-SAN': { product: 'PlusExpandable', color_id: 6 },
  'EXP-PLS48-DBRO': { product: 'PlusExpandable', color_id: 7 },
  'EXP-PLS49-LTAU': { product: 'PlusExpandable', color_id: 8 },

  'CME01-NAV': { product: 'Me', color_id: 0 },
  'CME02-CHA': { product: 'Me', color_id: 1 },
  'CME03-FOR': { product: 'Me', color_id: 2 },
  'CME04-NUD': { product: 'Me', color_id: 3 },
  'CME05-BUR': { product: 'Me', color_id: 4 },
  'CME06-MON': { product: 'Me', color_id: 5 },
  'CME07-CLA': { product: 'Me', color_id: 6 },
  'CME08-SKY': { product: 'Me', color_id: 7 },
  'CME09-SHA': { product: 'Me', color_id: 8 },

  'EVETOT-LAR21-NAT': { product: 'EverydayToteLarge', color_id: 0 },
  'EVETOT-LAR25-KHA': { product: 'EverydayToteLarge', color_id: 1 },
  'EVETOT-LAR24-MOS': { product: 'EverydayToteLarge', color_id: 2 },
  'EVETOT-LAR23-NAV': { product: 'EverydayToteLarge', color_id: 3 },
  'EVETOT-LAR22-BLA': { product: 'EverydayToteLarge', color_id: 4 },
  'EVETOT-LAR26-LIL': { product: 'EverydayToteLarge', color_id: 5 },
  'EVETOT-LAR27-COP': { product: 'EverydayToteLarge', color_id: 6 },
  'EVETOT2-LAR29-YEL': { product: 'EverydayToteLarge', color_id: 8 },
  'EVETOT2-LAR30-BRO': { product: 'EverydayToteLarge', color_id: 9 },
  'EVETOT2-LAR31-GRY': { product: 'EverydayToteLarge', color_id: 10 },

  'EVETOT2-LAR33-LBLU': { product: 'EverydayToteLargeResort', color_id: 14 },
  'EVETOT2-LAR32-RED': { product: 'EverydayToteLargeResort', color_id: 15 },
  'EVETOT2-LAR34-BIS': { product: 'EverydayToteLargeResort', color_id: 13 },

  'EVETOT2-LAR40-HGR': { product: 'EverydayToteLargeResort', color_id: 16 },
  'EVETOT2-LAR39-BPK': { product: 'EverydayToteLargeResort', color_id: 17 },
  'EVETOT2-LAR38-CBLU': { product: 'EverydayToteLargeResort', color_id: 18 },
	
  'EVETOT-SML31-NAT': { product: 'EverydayToteSmall', color_id: 0 },
  'EVETOT-SML35-KHA': { product: 'EverydayToteSmall', color_id: 1 },
  'EVETOT-SML34-MOS': { product: 'EverydayToteSmall', color_id: 2 },
  'EVETOT-SML33-NAV': { product: 'EverydayToteSmall', color_id: 3 },
  'EVETOT-SML32-BLA': { product: 'EverydayToteSmall', color_id: 4 },
  'EVETOT-SML36-LIL': { product: 'EverydayToteSmall', color_id: 5 },
  'EVETOT-SML37-COP': { product: 'EverydayToteSmall', color_id: 6 },
  'EVETOT2-SML40-YEL': { product: 'EverydayToteSmall', color_id: 8 },
  'EVETOT2-SML41-BRO': { product: 'EverydayToteSmall', color_id: 9 },
  'EVETOT2-SML42-GRY': { product: 'EverydayToteSmall', color_id: 10 },

  'EVETOT2-SML45-BIS': { product: 'EverydayToteSmallResort', color_id: 13 },
  'EVETOT2-SML44-LBLU': { product: 'EverydayToteSmallResort', color_id: 14 },
  'EVETOT2-SML43-RED': { product: 'EverydayToteSmallResort', color_id: 15 },
  
  'EVETOT2-SML48-HGR': { product: 'EverydayToteSmallResort', color_id: 16 },
  'EVETOT2-SML47-BPK': { product: 'EverydayToteSmallResort', color_id: 17 },
  'EVETOT2-SML46-CBLU': { product: 'EverydayToteSmallResort', color_id: 18 },

  'EVETOT2-SML36-NAT': { product: 'EverydayToteSmall', color_id: 0 },
  'EVETOT2-SML38-NAV': { product: 'EverydayToteSmall', color_id: 3 },
  'EVETOT2-SML37-BLA': { product: 'EverydayToteSmall', color_id: 4 },
  'EVETOT2-SML39-MOS': { product: 'EverydayToteSmall', color_id: 7 },

  'EVETOT2-LAR26-NAT': { product: 'EverydayToteLarge', color_id: 0 },
  'EVETOT2-LAR27-BLA': { product: 'EverydayToteLarge', color_id: 4 },
  'EVETOT2-LAR28-MOS': { product: 'EverydayToteLarge', color_id: 7 },

  // Everyday Tote Leather
  'EVETOT-LTD-SML46-TAN': { product: 'EverydayToteSmallLeather', color_id: 11 },
  'EVETOT-LTD-SML47-BLA': { product: 'EverydayToteSmallLeather', color_id: 12 },

  'EVETOT-LTD-LAR35-TAN': { product: 'EverydayToteLargeLeather', color_id: 11 },
  'EVETOT-LTD-LAR36-BLA': { product: 'EverydayToteLargeLeather', color_id: 12 },

  'EVETOT-LTD-MINI69-TAN': { product: 'EverydayToteMiniLeather', color_id: 5 },
  'EVETOT-LTD-MINI70-BLA': { product: 'EverydayToteMiniLeather', color_id: 6 },

  // Everyday Tote Mini
  'EVETOT-MINI62-BLA': { product: 'EverydayToteMini', color_id: 0 },
  'EVETOT-MINI64-BRO': { product: 'EverydayToteMini', color_id: 1 },
  'EVETOT-MINI63-YEL': { product: 'EverydayToteMini', color_id: 2 },
  'EVETOT-MINI65-GRY': { product: 'EverydayToteMini', color_id: 3 },
  'EVETOT-MINI61-NAT': { product: 'EverydayToteMini', color_id: 4 },

  'EVE-BPK41-NAT': { product: 'EverydayBackPack', color_id: 0 },
  'EVE-BPK42-BLA': { product: 'EverydayBackPack', color_id: 1 },
  'EVE-GARTOT51-NAT': { product: 'EverydayGarment', color_id: 0 },
  'EVE-GARTOT52-BLA': { product: 'EverydayGarment', color_id: 1 },

  'CAR-WKD02-BLA': { product: 'Weekender', color_id: 0 },
  'CAR-WKD03-FOR': { product: 'Weekender', color_id: 1 },
  'CAR-WKD04-CHK': { product: 'Weekender', color_id: 2 },
  'CAR-WKD01-NAV': { product: 'Weekender', color_id: 3 },

  'WKD02-CHA': { product: 'Weekender', color_id: 0 },
  'WKD03-FOR': { product: 'Weekender', color_id: 1 },
  'WKD04-NUD': { product: 'Weekender', color_id: 2 },
  'WKD01-NAV': { product: 'Weekender', color_id: 3 },


  'BPK01-NAV': { product: 'Backpack', color_id: 3 },
  'BPK02-CHA': { product: 'Backpack', color_id: 0 },
  'BPK03-FOR': { product: 'Backpack', color_id: 1 },
  'BPK04-NUD': { product: 'Backpack', color_id: 2 },

  'CAR-BPK02-BLA': { product: 'Backpack', color_id: 0 },
  'CAR-BPK02-CHA': { product: 'Backpack', color_id: 0 },
  'CAR-BPK03-FOR': { product: 'Backpack', color_id: 1 },
  'CAR-BPK04-CHK': { product: 'Backpack', color_id: 2 },
  'CAR-BPK01-NAV': { product: 'Backpack', color_id: 3 },

  'DBK-BPK02-NAV': { product: 'daybreak-backpack', color_id: 0 },
  'DBK-BPK01-BLA': { product: 'daybreak-backpack', color_id: 1 },
  'DBK-BPK03-TAU': { product: 'daybreak-backpack', color_id: 2 },
  'DBK-BPK04-MOS': { product: 'daybreak-backpack', color_id: 3 },

  'TOT02-CHA': { product: 'Tote', color_id: 0 },
  'TOT01-NAV': { product: 'Tote', color_id: 3 },
  'TOT03-FOR': { product: 'Tote', color_id: 1 },
  'TOT04-NUD': { product: 'Tote', color_id: 2 },
  'CAR-TOT02-BLA': { product: 'Tote', color_id: 0 },
  'CAR-TOT01-NAV': { product: 'Tote', color_id: 3 },
  'CAR-TOT04-CHK': { product: 'Tote', color_id: 2 },

  'PRO-COPB01-NAV': { product: 'Pro', color_id: 0 },
  'PRO-COPB02-CHA': { product: 'Pro', color_id: 1 },
  'PRO-COPB03-FOR': { product: 'Pro', color_id: 2 },
  'PRO-COPB04-MON': { product: 'Pro', color_id: 3 },

  'PRO-COPB07-CLA': { product: 'Pro', color_id: 4 },
  'PRO-COPB11-OLI': { product: 'Pro', color_id: 5 },
  'PRO-COPB12-NAV': { product: 'Pro', color_id: 6 },
  'PRO-COPB13-FOR': { product: 'Pro', color_id: 7 },

  'PROV2-COPB22-CHA': { product: 'pro-v2', color_id: 0 },
  'PROV2-COPB25-CLA': { product: 'pro-v2', color_id: 1 },
  'PROV2-COPB23-FOR': { product: 'pro-v2', color_id: 2 },
  'PROV2-COPB26-SHA': { product: 'pro-v2', color_id: 3 },
  'PROV2-COPB27-OLI': { product: 'pro-v2', color_id: 4 },
  'PROV2-COPB21-NAV': { product: 'pro-v2', color_id: 5 },
  'PROV2-COPB24-SAN': { product: 'pro-v2', color_id: 6 },

  'CAR-DPK02-BLA': { product: 'Daypack', color_id: 0 },
  'CAR-DPK03-FOR': { product: 'Daypack', color_id: 1 },
  'CAR-DPK04-CHK': { product: 'Daypack', color_id: 2 },
  'CAR-DPK01-NAV': { product: 'Daypack', color_id: 3 },

  // Light Carry On
  'LGT-CO1-NAV': { product: 'Light', color_id: 0 },
  'LGT-CO2-CHA': { product: 'Light', color_id: 1 },
  'LGT-CO3-FOR': { product: 'Light', color_id: 2 },
  'LGT-CO4-SAN': { product: 'Light', color_id: 3 },
  'LGT-CO5-BUR': { product: 'Light', color_id: 4 },
  'LGT-CO6-MON': { product: 'Light', color_id: 5 },
  'LGT-CO7-CLA': { product: 'Light', color_id: 6 },
  'LGT-CO8-SKY': { product: 'Light', color_id: 7 },
  'LGT-CO9-SHA': { product: 'Light', color_id: 8 },
  'LGT-CO10-GRY': { product: 'Light', color_id: 9 },
  'LGT-CO11-OLI': { product: 'Light', color_id: 10 },
  'LGT-CO12-PIN': { product: 'Light', color_id: 11 },
  'LGT-CO13-YEL': { product: 'Light', color_id: 12 },
  'LGT-CO14-BLU': { product: 'Light', color_id: 13 },
  'LGT-CO15-MAG': { product: 'Light', color_id: 14 },
  'LGT-CO15-CGRN': { product: 'Light', color_id: 15 },
  'LGT-CO16-MORA': { product: 'Light', color_id: 16 },
  'LGT-CO17-DBRO': { product: 'Light', color_id: 17 },

  // Light Carry On Expandable
  'LGT-COEX21-NAV': { product: 'LightExpandable', color_id: 0 },
  'LGT-COEX22-CHA': { product: 'LightExpandable', color_id: 1 },
  'LGT-COEX23-FOR': { product: 'LightExpandable', color_id: 2 },
  'LGT-COEX24-SAN': { product: 'LightExpandable', color_id: 3 },
  'LGT-COEX25-CLA': { product: 'LightExpandable', color_id: 4 },
  'LGT-COEX26-SHA': { product: 'LightExpandable', color_id: 5 },
  'LGT-COEX27-OLI': { product: 'LightExpandable', color_id: 6 },
  'LGT-COEX28-CGRN': { product: 'LightExpandable', color_id: 7 },
  'LGT-COEX29-MORA': { product: 'LightExpandable', color_id: 8 },
  'LGT-COEX30-DBRO': { product: 'LightExpandable', color_id: 9 },

  // Light Checked
  'LGT-CHK41-NAV': { product: 'LightChecked', color_id: 0 },
  'LGT-CHK42-CHA': { product: 'LightChecked', color_id: 1 },
  'LGT-CHK43-FOR': { product: 'LightChecked', color_id: 2 },
  'LGT-CHK44-SAN': { product: 'LightChecked', color_id: 3 },
  'LGT-CHK45-CLA': { product: 'LightChecked', color_id: 4 },
  'LGT-CHK46-SHA': { product: 'LightChecked', color_id: 5 },
  'LGT-CHK47-OLI': { product: 'LightChecked', color_id: 6 },
  'LGT-CHK48-CGRN': { product: 'LightChecked', color_id: 7 },
  'LGT-CHK49-MORA': { product: 'LightChecked', color_id: 8 },
  'LGT-CHK50-DBRO': { product: 'LightChecked', color_id: 9 },

  // Light Checked Plus
  'LGT-PLS61-NAV': { product: 'LightCheckedPlus', color_id: 0 },
  'LGT-PLS62-CHA': { product: 'LightCheckedPlus', color_id: 1 },
  'LGT-PLS63-FOR': { product: 'LightCheckedPlus', color_id: 2 },
  'LGT-PLS64-SAN': { product: 'LightCheckedPlus', color_id: 3 },
  'LGT-PLS65-CLA': { product: 'LightCheckedPlus', color_id: 4 },
  'LGT-PLS66-SHA': { product: 'LightCheckedPlus', color_id: 5 },
  'LGT-PLS67-OLI': { product: 'LightCheckedPlus', color_id: 6 },
  'LGT-PLS68-CGRN': { product: 'LightCheckedPlus', color_id: 7 },
  'LGT-PLS69-MORA': { product: 'LightCheckedPlus', color_id: 8 },
  'LGT-PLS70-DBRO': { product: 'LightCheckedPlus', color_id: 9 },

  'SET-LGT-CHKCO80-DBRO': { product: 'LightChecked', color_id: 9 },
  'SET-LGT-PLSCO80-DBRO': { product: 'LightCheckedPlus', color_id: 9 },
  'SET-LGT-FMLY80-DBRO': { product: 'LightCheckedPlus', color_id: 9 },

  'SET-LGTEX-CHKCO90-DBRO': { product: 'LightChecked', color_id: 9 },
  'SET-LGTEX-PLSCO80-DBRO': { product: 'LightCheckedPlus', color_id: 9 },
  'SET-LGTEX-FMLY80-DBRO': { product: 'LightCheckedPlus', color_id: 9 },

  'SET-EXP-CHKCOPB51-CHA': { product: 'CheckedExpandable', color_id: 0 },
  'SET-EXP-CHKCOPB52-FOR': { product: 'CheckedExpandable', color_id: 1 },
  'SET-EXP-CHKCOPB53-OLI': { product: 'CheckedExpandable', color_id: 2 },
  'SET-EXP-CHKCOPB54-NAV': { product: 'CheckedExpandable', color_id: 3 },
  'SET-EXP-CHKCOPB55-CLY': { product: 'CheckedExpandable', color_id: 4 },
  'SET-EXP-CHKCOPB56-SHW': { product: 'CheckedExpandable', color_id: 5 },
  'SET-EXP-CHKCOPB57-SAN': { product: 'CheckedExpandable', color_id: 6 },
  'SET-EXP-CHKCOPB58-DBRO': { product: 'CheckedExpandable', color_id: 7 },
  'SET-EXP-CHKCOPB59-LTAU': { product: 'CheckedExpandable', color_id: 8 },

  'SET-EXP-PLSCOPB61-CHA': { product: 'PlusExpandable', color_id: 0 },
  'SET-EXP-PLSCOPB62-FOR': { product: 'PlusExpandable', color_id: 1 },
  'SET-EXP-PLSCOPB63-OLI': { product: 'PlusExpandable', color_id: 2 },
  'SET-EXP-PLSCOPB64-NAV': { product: 'PlusExpandable', color_id: 3 },
  'SET-EXP-PLSCOPB65-CLY': { product: 'PlusExpandable', color_id: 4 },
  'SET-EXP-PLSCOPB66-SHW': { product: 'PlusExpandable', color_id: 5 },
  'SET-EXP-PLSCOPB67-SAN': { product: 'PlusExpandable', color_id: 6 },
  'SET-EXP-PLSCOPB58-DBRO': { product: 'PlusExpandable', color_id: 7 },
  'SET-EXP-PLSCOPB59-LTAU': { product: 'PlusExpandable', color_id: 8 },

  'SET-EXP-FMLY71-CHA': { product: 'PlusExpandable', color_id: 0 },
  'SET-EXP-FMLY72-FOR': { product: 'PlusExpandable', color_id: 1 },
  'SET-EXP-FMLY73-OLI': { product: 'PlusExpandable', color_id: 2 },
  'SET-EXP-FMLY74-NAV': { product: 'PlusExpandable', color_id: 3 },
  'SET-EXP-FMLY75-CLY': { product: 'PlusExpandable', color_id: 4 },
  'SET-EXP-FMLY76-SHW': { product: 'PlusExpandable', color_id: 5 },
  'SET-EXP-FMLY77-SAN': { product: 'PlusExpandable', color_id: 6 },
  'SET-EXP-FMLY78-DBRO': { product: 'PlusExpandable', color_id: 7 },
  'SET-EXP-FMLY79-LTAU': { product: 'PlusExpandable', color_id: 8 },


  // Light Carry & Checked Set
  'SET-LGT-CHKCO71-NAV': { product: 'LightChecked', color_id: 0 },
  'SET-LGT-CHKCO72-CHA': { product: 'LightChecked', color_id: 1 },
  'SET-LGT-CHKCO73-FOR': { product: 'LightChecked', color_id: 2 },
  'SET-LGT-CHKCO74-SAN': { product: 'LightChecked', color_id: 3 },
  'SET-LGT-CHKCO75-CLA': { product: 'LightChecked', color_id: 4 },
  'SET-LGT-CHKCO76-SHA': { product: 'LightChecked', color_id: 5 },
  'SET-LGT-CHKCO77-OLI': { product: 'LightChecked', color_id: 6 },
  'SET-LGT-CHKCO78-CGRN': { product: 'LightChecked', color_id: 7 },
  'SET-LGT-CHKCO79-MORA': { product: 'LightChecked', color_id: 8 },
  'SET-LGT-CHKCO80-DBRO': { product: 'LightChecked', color_id: 9 },

  // Light Carry & Plus Set
  'SET-LGT-PLSCO71-NAV': { product: 'LightCheckedPlus', color_id: 0 },
  'SET-LGT-PLSCO72-CHA': { product: 'LightCheckedPlus', color_id: 1 },
  'SET-LGT-PLSCO73-FOR': { product: 'LightCheckedPlus', color_id: 2 },
  'SET-LGT-PLSCO74-SAN': { product: 'LightCheckedPlus', color_id: 3 },
  'SET-LGT-PLSCO75-CLA': { product: 'LightCheckedPlus', color_id: 4 },
  'SET-LGT-PLSCO76-SHA': { product: 'LightCheckedPlus', color_id: 5 },
  'SET-LGT-PLSCO77-OLI': { product: 'LightCheckedPlus', color_id: 6 },
  'SET-LGT-PLSCO78-CGRN': { product: 'LightCheckedPlus', color_id: 7 },
  'SET-LGT-PLSCO79-MORA': { product: 'LightCheckedPlus', color_id: 8 },
  'SET-LGT-PLSCO80-DBRO': { product: 'LightCheckedPlus', color_id: 9 },

  // Light Expandable Carry & Light Checked Set
  'SET-LGTEX-CHKCO81-NAV': { product: 'LightChecked', color_id: 0 },
  'SET-LGTEX-CHKCO82-CHA': { product: 'LightChecked', color_id: 1 },
  'SET-LGTEX-CHKCO83-FOR': { product: 'LightChecked', color_id: 2 },
  'SET-LGTEX-CHKCO84-SAN': { product: 'LightChecked', color_id: 3 },
  'SET-LGTEX-CHKCO85-CLA': { product: 'LightChecked', color_id: 4 },
  'SET-LGTEX-CHKCO86-SHA': { product: 'LightChecked', color_id: 5 },
  'SET-LGTEX-CHKCO87-OLI': { product: 'LightChecked', color_id: 6 },
  'SET-LGTEX-CHKCO88-CGRN': { product: 'LightChecked', color_id: 7 },
  'SET-LGTEX-CHKCO89-MORA': { product: 'LightChecked', color_id: 8 },
  'SET-LGTEX-CHKCO90-DBRO': { product: 'LightChecked', color_id: 9 },

  // Light Expandable Carry & Light Checked Set
  'SET-LGTEX-PLSCO71-NAV': { product: 'LightChecked', color_id: 0 },
  'SET-LGTEX-PLSCO72-CHA': { product: 'LightChecked', color_id: 1 },
  'SET-LGTEX-PLSCO73-FOR': { product: 'LightChecked', color_id: 2 },
  'SET-LGTEX-PLSCO74-SAN': { product: 'LightChecked', color_id: 3 },
  'SET-LGTEX-PLSCO75-CLA': { product: 'LightChecked', color_id: 4 },
  'SET-LGTEX-PLSCO76-SHA': { product: 'LightChecked', color_id: 5 },
  'SET-LGTEX-PLSCO77-OLI': { product: 'LightChecked', color_id: 6 },
  'SET-LGTEX-PLSCO78-CGRN': { product: 'LightChecked', color_id: 7 },
  'SET-LGTEX-PLSCO79-MORA': { product: 'LightChecked', color_id: 8 },
  'SET-LGTEX-PLSCO80-DBRO': { product: 'LightChecked', color_id: 9 },

  // Light Expandable Family Set
  'SET-LGTEX-FMLY71-NAV': { product: 'LightCheckedPlus', color_id: 0 },
  'SET-LGTEX-FMLY72-CHA': { product: 'LightCheckedPlus', color_id: 1 },
  'SET-LGTEX-FMLY73-FOR': { product: 'LightCheckedPlus', color_id: 2 },
  'SET-LGTEX-FMLY74-SAN': { product: 'LightCheckedPlus', color_id: 3 },
  'SET-LGTEX-FMLY75-CLA': { product: 'LightCheckedPlus', color_id: 4 },
  'SET-LGTEX-FMLY76-SHA': { product: 'LightCheckedPlus', color_id: 5 },
  'SET-LGTEX-FMLY77-OLI': { product: 'LightCheckedPlus', color_id: 6 },
  'SET-LGTEX-FMLY78-CGRN': { product: 'LightCheckedPlus', color_id: 7 },
  'SET-LGTEX-FMLY79-MORA': { product: 'LightCheckedPlus', color_id: 8 },
  'SET-LGTEX-FMLY80-DBRO': { product: 'LightCheckedPlus', color_id: 9 },

  // Light  Family Set
  'SET-LGT-FMLY71-NAV': { product: 'LightCheckedPlus', color_id: 0 },
  'SET-LGT-FMLY72-CHA': { product: 'LightCheckedPlus', color_id: 1 },
  'SET-LGT-FMLY73-FOR': { product: 'LightCheckedPlus', color_id: 2 },
  'SET-LGT-FMLY74-SAN': { product: 'LightCheckedPlus', color_id: 3 },
  'SET-LGT-FMLY75-CLA': { product: 'LightCheckedPlus', color_id: 4 },
  'SET-LGT-FMLY76-SHA': { product: 'LightCheckedPlus', color_id: 5 },
  'SET-LGT-FMLY77-OLI': { product: 'LightCheckedPlus', color_id: 6 },
  'SET-LGT-FMLY78-CGRN': { product: 'LightCheckedPlus', color_id: 7 },
  'SET-LGT-FMLY79-MORA': { product: 'LightCheckedPlus', color_id: 8 },
  'SET-LGT-FMLY80-DBRO': { product: 'LightCheckedPlus', color_id: 9 },

  'BOT-LAR01-BLA': { product: 'bottle-750', color_id: 0 },
  'BOT-LAR02-NAV': { product: 'bottle-750', color_id: 1 },
  'BOT-LAR05-MOS': { product: 'bottle-750', color_id: 2 },
  'BOT-LAR06-PIN': { product: 'bottle-750', color_id: 3 },
  'BOT-LAR07-YEL': { product: 'bottle-750', color_id: 4 },
  'BOT-LAR08-BLU': { product: 'bottle-750', color_id: 5 },
  'BOT-LAR11-MAG': { product: 'bottle-750', color_id: 6 },
  'BOT-LAR12-CBLU': { product: 'bottle-750', color_id: 7 },

  'BOT-SML01-BLA': { product: 'Bottle', color_id: 0 },
  'BOT-SML02-NAV': { product: 'Bottle', color_id: 1 },
  'BOT-SML03-LGR': { product: 'Bottle', color_id: 2 },
  'BOT-SML04-CLA': { product: 'Bottle', color_id: 3 },
  'BOT-SML05-MOS': { product: 'Bottle', color_id: 4 },
  'BOT-SML06-PIN': { product: 'Bottle', color_id: 5 },
  'BOT-SML07-YEL': { product: 'Bottle', color_id: 6 },
  'BOT-SML08-BLU': { product: 'Bottle', color_id: 7 },
  'BOT-SML09-MAG': { product: 'Bottle', color_id: 8 },
  'BOT-SML10-CBLU': { product: 'Bottle', color_id: 9 },

  'IPC-MAX01-BLA': { product: 'iphone-max', color_id: 0 },
  'IPC-MAX02-NAV': { product: 'iphone-max', color_id: 1 },
  'IPC-MAX03-FOR': { product: 'iphone-max', color_id: 2 },
  'IPC-MAX04-TAU': { product: 'iphone-max', color_id: 3 },

  'IPC-MIN01-BLA': { product: 'iphone-mini', color_id: 0 },
  'IPC-MIN02-NAV': { product: 'iphone-mini', color_id: 1 },
  'IPC-MIN03-FOR': { product: 'iphone-mini', color_id: 2 },
  'IPC-MIN04-TAU': { product: 'iphone-mini', color_id: 3 },

  'IPC-PRO01-BLA': { product: 'iphone-classic', color_id: 0 },
  'IPC-PRO02-NAV': { product: 'iphone-classic', color_id: 1 },
  'IPC-PRO03-FOR': { product: 'iphone-classic', color_id: 2 },
  'IPC-PRO04-TAU': { product: 'iphone-classic', color_id: 3 },

  'TRK-COPB01-SAN': { product: 'trunk-carry', color_id: 0 },
  'TRK-COPB02-GRY': { product: 'trunk-carry', color_id: 1 },
  'TRK-COPB03-GRE': { product: 'trunk-carry', color_id: 2 },
  'TRK-COPB04-RED': { product: 'trunk-carry', color_id: 3 },
  'TRK-COPB05-MBLA': { product: 'trunk-carry', color_id: 4 },
  'TRK-COPB06-CBLU': { product: 'trunk-carry', color_id: 5 },
  'TRK-COPB07-BRO': { product: 'trunk-carry-heritage', color_id: 0 },
  'TRK-COPB08-MOS': { product: 'trunk-carry-heritage', color_id: 1 },
  'TRK-COPB09-LGRY': { product: 'trunk-carry-heritage', color_id: 2 },
  'TRK-COPB10-CRM': { product: 'trunk-carry-limited', color_id: 0 },
  'TRK-COPB11-FBLU': { product: 'trunk-carry-limited', color_id: 1 },
  'TRK-COPB12-PLUM': { product: 'trunk-carry-limited', color_id: 2 },

  'TRK-COPB13-MSAN': { product: 'trunk-carry-matte', color_id: 0 },
  'TRK-COPB14-MGRE': { product: 'trunk-carry-matte', color_id: 1 },
  'TRK-COPB15-MRED': { product: 'trunk-carry-matte', color_id: 2 },

  'TRK-CHK01-SAN': { product: 'trunk-checked', color_id: 0 },
  'TRK-CHK02-GRY': { product: 'trunk-checked', color_id: 1 },
  'TRK-CHK03-GRE': { product: 'trunk-checked', color_id: 2 },
  'TRK-CHK04-RED': { product: 'trunk-checked', color_id: 3 },
  'TRK-CHK05-MBLA': { product: 'trunk-checked', color_id: 4 },
  'TRK-CHK06-CBLU': { product: 'trunk-checked', color_id: 5 },
  'TRK-CHK07-BRO': { product: 'trunk-checked-heritage', color_id: 0 },
  'TRK-CHK08-MOS': { product: 'trunk-checked-heritage', color_id: 1 },
  'TRK-CHK09-LGRY': { product: 'trunk-checked-heritage', color_id: 2 },
  'TRK-CHK10-CRM': { product: 'trunk-checked-limited', color_id: 0 },
  'TRK-CHK11-FBLU': { product: 'trunk-checked-limited', color_id: 1 },
  'TRK-CHK12-PLUM': { product: 'trunk-checked-limited', color_id: 2 },

  'TRK-CHK13-MSAN': { product: 'trunk-checked-matte', color_id: 0 },
  'TRK-CHK14-MGRE': { product: 'trunk-checked-matte', color_id: 1 },
  'TRK-CHK15-MRED': { product: 'trunk-checked-matte', color_id: 2 },

  'TRK-PLS23-GRE': { product: 'trunk-checked-plus', color_id: 0 },
  'TRK-PLS24-RED': { product: 'trunk-checked-plus', color_id: 1 },
  'TRK-PLS21-SAN': { product: 'trunk-checked-plus', color_id: 2 },
  'TRK-PLS25-MBLA': { product: 'trunk-checked-plus', color_id: 3 },
  'TRK-PLS27-BRO': { product: 'trunk-checked-plus', color_id: 4 },
  'TRK-PLS28-MOS': { product: 'trunk-checked-plus', color_id: 5 },
  'TRK-PLS29-LGRY': { product: 'trunk-checked-plus', color_id: 6 },
  'TRK-PLS33-MSAN': { product: 'trunk-checked-plus', color_id: 7 },
  'TRK-PLS34-MGRE': { product: 'trunk-checked-plus', color_id: 8 },
  'TRK-PLS35-MRED': { product: 'trunk-checked-plus', color_id: 9 },

  'SET-TRK-FMLY03-GRE': { product: 'trunk-checked-plus', color_id: 0 },
  'SET-TRK-FMLY04-RED': { product: 'trunk-checked-plus', color_id: 1 },
  'SET-TRK-FMLY01-SAN': { product: 'trunk-checked-plus', color_id: 2 },
  'SET-TRK-FMLY05-MBLA': { product: 'trunk-checked-plus', color_id: 3 },
  'SET-TRK-FMLY07-BRO': { product: 'trunk-checked-plus', color_id: 4 },
  'SET-TRK-FMLY08-MOS': { product: 'trunk-checked-plus', color_id: 5 },
  'SET-TRK-FMLY09-LGRY': { product: 'trunk-checked-plus', color_id: 6 },
  'SET-TRK-FMLY13-MSAN': { product: 'trunk-checked-plus', color_id: 7 },
  'SET-TRK-FMLY14-MGRE': { product: 'trunk-checked-plus', color_id: 8 },
  'SET-TRK-FMLY15-MRED': { product: 'trunk-checked-plus', color_id: 9 },

  'TRK-PLS33-MSAN': { product: 'trunk-checked-plus', color_id: 7 },
  'TRK-PLS34-MGRE': { product: 'trunk-checked-plus', color_id: 8 },
  'TRK-PLS35-MRED': { product: 'trunk-checked-plus', color_id: 9 },

  // Trunk Pro
  'TRK-PRO-COPB03-GRE': { product: 'trunk-pro', color_id: 0 },
  'TRK-PRO-COPB05-MBLA': { product: 'trunk-pro', color_id: 1 },
  'TRK-PRO-COPB04-RED': { product: 'trunk-pro', color_id: 2 },
  'TRK-PRO-COPB01-SAN': { product: 'trunk-pro', color_id: 3 },

  'CAR-WKDP02-BLA': { product: 'weekender-plus', color_id: 0 },
  'CAR-WKDP03-CHK': { product: 'weekender-plus', color_id: 1 },
  'CAR-WKDP01-NAV': { product: 'weekender-plus', color_id: 2 },

  'SET-FMLY01-NAV': { product: 'Plus', color_id: 0 },
  'SET-FMLY02-CHA': { product: 'Plus', color_id: 1 },
  'SET-FMLY03-FOR': { product: 'Plus', color_id: 2 },
  'SET-FMLY04-NUD': { product: 'Plus', color_id: 3 },
  'SET-FMLY05-BUR': { product: 'Plus', color_id: 4 },
  'SET-FMLY06-MON': { product: 'Plus', color_id: 5 },
  'SET-FMLY07-CLY': { product: 'Plus', color_id: 6 },
  'SET-FMLY08-SKY': { product: 'Plus', color_id: 7 },
  'SET-FMLY09-SHW': { product: 'Plus', color_id: 8 },
  'SET-FMLY11-OLI': { product: 'Plus', color_id: 9 },
  'SET-FMLY13-YEL': { product: 'Plus', color_id: 10 },
  'SET-FMLY14-BLU': { product: 'Plus', color_id: 11 },
  'SET-FMLY15-MAG': { product: 'Plus', color_id: 12 },

  'SET-FMLY19-CHA-RED': { product: 'Plus', color_id: 15 },
  'SET-FMLY20-CHA-BLU': { product: 'Plus', color_id: 16 },
  'SET-FMLY21-CHA-YEL': { product: 'Plus', color_id: 17 },
  'SET-FMLY16-GRN': { product: 'Plus', color_id: 18 },
  'SET-FMLY17-RED': { product: 'Plus', color_id: 19 },
  'SET-FMLY18-DKB': { product: 'Plus', color_id: 20 },
  'SET-FMLY23-ORA': { product: 'Plus', color_id: 21 },
  'SET-FMLY22-BPK': { product: 'Plus', color_id: 22 },
  'SET-FMLY24-SBLU': { product: 'Plus', color_id: 23 },
  'SET-FMLY25-BONE': { product: 'Plus', color_id: 24 },

  'SET-TRK-CHKCOPB01-SAN': { product: 'trunk-checked', color_id: 0 },
  'SET-TRK-CHKCOPB02-GRY': { product: 'trunk-checked', color_id: 1 },
  'SET-TRK-CHKCOPB03-GRE': { product: 'trunk-checked', color_id: 2 },
  'SET-TRK-CHKCOPB04-RED': { product: 'trunk-checked', color_id: 3 },
  'SET-TRK-CHKCOPB05-MBLA': { product: 'trunk-checked', color_id: 4 },
  'SET-TRK-CHKCOPB06-CBLU': { product: 'trunk-checked', color_id: 5 },
  'SET-TRK-CHKCOPB07-BRO': { product: 'trunk-checked-heritage', color_id: 0 },
  'SET-TRK-CHKCOPB08-MOS': { product: 'trunk-checked-heritage', color_id: 1 },
  'SET-TRK-CHKCOPB09-LGRY': { product: 'trunk-checked-heritage', color_id: 2 },
  'SET-TRK-CHKCOPB10-CRM': { product: 'trunk-checked-limited', color_id: 0 },
  'SET-TRK-CHKCOPB11-FBLU': { product: 'trunk-checked-limited', color_id: 1 },
  'SET-TRK-CHKCOPB12-PLUM': { product: 'trunk-checked-limited', color_id: 2 },

  'SET-TRK-CHKCOPB13-MSAN': { product: 'trunk-checked-matte', color_id: 0 },
  'SET-TRK-CHKCOPB14-MGRE': { product: 'trunk-checked-matte', color_id: 1 },
  'SET-TRK-CHKCOPB15-MRED': { product: 'trunk-checked-matte', color_id: 2 },

  'SET-CHKCOPB01-NAV': { product: 'Checked', color_id: 0 },
  'SET-CHKCOPB02-CHA': { product: 'Checked', color_id: 1 },
  'SET-CHKCOPB03-FOR': { product: 'Checked', color_id: 2 },
  'SET-CHKCOPB04-NUD': { product: 'Checked', color_id: 3 },
  'SET-CHKCOPB05-BUR': { product: 'Checked', color_id: 4 },
  'SET-CHKCOPB06-MON': { product: 'Checked', color_id: 5 },
  'SET-CHKCOPB07-CLY': { product: 'Checked', color_id: 6 },
  'SET-CHKCOPB08-SKY': { product: 'Checked', color_id: 7 },
  'SET-CHKCOPB09-SHW': { product: 'Checked', color_id: 8 },
  'SET-CHKCOPB10-GRY': { product: 'Checked', color_id: 9 },
  'SET-CHKCOPB11-OLI': { product: 'Checked', color_id: 10 },
  'SET-CHKCOPB12-PIN': { product: 'Checked', color_id: 11 },
  'SET-CHKCOPB13-YEL': { product: 'Checked', color_id: 12 },
  'SET-CHKCOPB14-BLU': { product: 'Checked', color_id: 13 },
  'SET-CHKCOPB15-MAG': { product: 'Checked', color_id: 14 },

  'SET-CHKCOPB19-CHA-RED': { product: 'Checked', color_id: 15 },
  'SET-CHKCOPB20-CHA-BLU': { product: 'Checked', color_id: 16 },
  'SET-CHKCOPB21-CHA-YEL': { product: 'Checked', color_id: 17 },
  'SET-CHKCOPB16-GRN': { product: 'Checked', color_id: 18 },
  'SET-CHKCOPB17-RED': { product: 'Checked', color_id: 19 },
  'SET-CHKCOPB18-DKB': { product: 'Checked', color_id: 20 },
  'SET-CHKCOPB23-ORA': { product: 'Checked', color_id: 21 },
  'SET-CHKCOPB22-BPK': { product: 'Checked', color_id: 22 },
  'SET-CHKCOPB24-SBLU': { product: 'Checked', color_id: 23 },
  'SET-CHKCOPB25-BONE': { product: 'Checked', color_id: 24 },

  'NBKA5-SOFT01-BLA': { product: 'notebook', color_id: 0 },
  'NBKA5-SOFT02-SAP': { product: 'notebook', color_id: 1 },
  'NBKA5-SOFT03-HYD': { product: 'notebook', color_id: 2 },
  'NBKA5-SOFT04-RED': { product: 'notebook', color_id: 3 },
  'NBKA5-SOFT05-GRE': { product: 'notebook', color_id: 4 },
  'NBKA5-HARD01-BLA': { product: 'notebook', color_id: 0 },
  'NBKA5-HARD02-SAP': { product: 'notebook', color_id: 1 },
  'NBKA5-HARD03-HYD': { product: 'notebook', color_id: 2 },
  'NBKA5-HARD04-RED': { product: 'notebook', color_id: 3 },
  'NBKA5-HARD05-GRE': { product: 'notebook', color_id: 4 },

  'TAG01-BLA': { product: 'luggage-tag', color_id: 0 },
  'TAG02-GRY': { product: 'luggage-tag', color_id: 1 },

  'SET-PLSCOPB01-NAV': { product: 'Plus', color_id: 0 },
  'SET-PLSCOPB02-CHA': { product: 'Plus', color_id: 1 },
  'SET-PLSCOPB03-FOR': { product: 'Plus', color_id: 2 },
  'SET-PLSCOPB04-NUD': { product: 'Plus', color_id: 3 },
  'SET-PLSCOPB05-BUR': { product: 'Plus', color_id: 4 },
  'SET-PLSCOPB06-MON': { product: 'Plus', color_id: 5 },
  'SET-PLSCOPB07-CLY': { product: 'Plus', color_id: 6 },
  'SET-PLSCOPB08-SKY': { product: 'Plus', color_id: 7 },
  'SET-PLSCOPB09-SHW': { product: 'Plus', color_id: 8 },
  'SET-PLSCOPB11-OLI': { product: 'Plus', color_id: 9 },
  'SET-PLSCOPB13-YEL': { product: 'Plus', color_id: 10 },
  'SET-PLSCOPB14-BLU': { product: 'Plus', color_id: 11 },
  'SET-PLSCOPB15-MAG': { product: 'Plus', color_id: 12 },

  'SET-PLSCOPB19-CHA-RED': { product: 'Plus', color_id: 15 },
  'SET-PLSCOPB20-CHA-BLU': { product: 'Plus', color_id: 16 },
  'SET-PLSCOPB21-CHA-YEL': { product: 'Plus', color_id: 17 },
  'SET-PLSCOPB16-GRN': { product: 'Plus', color_id: 18 },
  'SET-PLSCOPB17-RED': { product: 'Plus', color_id: 19 },
  'SET-PLSCOPB18-DKB': { product: 'Plus', color_id: 20 },
  'SET-PLSCOPB23-ORA': { product: 'Plus', color_id: 21 },
  'SET-PLSCOPB22-BPK': { product: 'Plus', color_id: 22 },
  'SET-PLSCOPB24-SBLU': { product: 'Plus', color_id: 23 },
  'SET-PLSCOPB25-BONE': { product: 'Plus', color_id: 24 },

  // Carry On Classic & Checked Expanable Set
  'SET-EXP-CHKCOPB51-CHA': { product: 'CheckedExpandable', color_id: 0 },
  'SET-EXP-CHKCOPB52-FOR	': { product: 'CheckedExpandable', color_id: 1 },
  'SET-EXP-CHKCOPB53-OLI': { product: 'CheckedExpandable', color_id: 2 },
  'SET-EXP-CHKCOPB54-NAV': { product: 'CheckedExpandable', color_id: 3 },
  'SET-EXP-CHKCOPB55-CLY': { product: 'CheckedExpandable', color_id: 4 },
  'SET-EXP-CHKCOPB56-SHW': { product: 'CheckedExpandable', color_id: 5 },
  'SET-EXP-CHKCOPB57-SAN': { product: 'CheckedExpandable', color_id: 6 },
  'SET-EXP-CHKCOPB58-DBRO': { product: 'CheckedExpandable', color_id: 7 },
  'SET-EXP-CHKCOPB59-LTAU': { product: 'CheckedExpandable', color_id: 8 },

  // Carry On Classic & Checked Plus Expanable Set
  'SET-EXP-PLSCOPB61-CHA': { product: 'PlusExpandable', color_id: 0 },
  'SET-EXP-PLSCOPB62-FOR': { product: 'PlusExpandable', color_id: 1 },
  'SET-EXP-PLSCOPB63-OLI': { product: 'PlusExpandable', color_id: 2 },
  'SET-EXP-PLSCOPB64-NAV': { product: 'PlusExpandable', color_id: 3 },
  'SET-EXP-PLSCOPB65-CLY': { product: 'PlusExpandable', color_id: 4 },
  'SET-EXP-PLSCOPB66-SHW': { product: 'PlusExpandable', color_id: 5 },
  'SET-EXP-PLSCOPB67-SAN': { product: 'PlusExpandable', color_id: 6 },
  'SET-EXP-PLSCOPB58-DBRO': { product: 'PlusExpandable', color_id: 7 },
  'SET-EXP-PLSCOPB59-LTAU': { product: 'PlusExpandable', color_id: 8 },

  'TEC01-BLA': { product: 'tech-kit', color_id: 0 },
  'TEC02-RED': { product: 'tech-kit', color_id: 1 },
  'TEC03-BLU': { product: 'tech-kit', color_id: 2 },
  'TEC04-YEL': { product: 'tech-kit', color_id: 3 },

  'CME21-NAV': { product: 'Me-V2', color_id: 0 },
  'CME22-CHA': { product: 'Me-V2', color_id: 1 },
  'CME23-FOR': { product: 'Me-V2', color_id: 2 },
  'CME24-SAN': { product: 'Me-V2', color_id: 3 },
  'CME25-CLA': { product: 'Me-V2', color_id: 4 },
  'CME26-SHA': { product: 'Me-V2', color_id: 5 },
  'CME27-YEL': { product: 'Me-V2', color_id: 6 },
  'CME28-BLU': { product: 'Me-V2', color_id: 7 },
  'CME29-MAG': { product: 'Me-V2', color_id: 8 },

  'CME30-MOS': { product: 'Me-V2', color_id: 9 },
  'CME31-SKY': { product: 'Me-V2', color_id: 10 },
  'CME32-DBRO': { product: 'Me-V2', color_id: 11 },
  'CME33-LTAU': { product: 'Me-V2', color_id: 12 },

  // Small Goods
  'SLG-TAG40-BLA': { product: 'arch-tag', color_id: 0 },
  'SLG-TAG43-BRO': { product: 'arch-tag', color_id: 1 },
  'SLG-TAG41-OYS': { product: 'arch-tag', color_id: 2 },
  'SLG-TAG42-PLM': { product: 'arch-tag', color_id: 3 },

  'SLG-CARD01-BLA': { product: 'cardholder', color_id: 0 },
  'SLG-CARD04-BRO': { product: 'cardholder', color_id: 1 },
  'SLG-CARD02-OYS': { product: 'cardholder', color_id: 2 },
  'SLG-CARD03-PLM': { product: 'cardholder', color_id: 3 },
  'SLG-CARD05-RED': { product: 'cardholder', color_id: 4 },

  'SLG-TAG20-BLA': { product: 'circle-tag', color_id: 0 },
  'SLG-TAG23-BRO': { product: 'circle-tag', color_id: 1 },
  'SLG-TAG21-OYS': { product: 'circle-tag', color_id: 2 },
  'SLG-TAG22-PLM': { product: 'circle-tag', color_id: 3 },
  'SLG-TAG25-RED': { product: 'circle-tag', color_id: 4 },

  'SLG-TAG10-BLA': { product: 'classic-tag', color_id: 0 },
  'SLG-TAG13-BRO': { product: 'classic-tag', color_id: 1 },
  'SLG-TAG11-OYS': { product: 'classic-tag', color_id: 2 },
  'SLG-TAG12-PLM': { product: 'classic-tag', color_id: 3 },
  'SLG-TAG15-RED': { product: 'classic-tag', color_id: 4 },

  'SLG-TAG30-BLA': { product: 'flower-tag', color_id: 0 },
  'SLG-TAG33-BRO': { product: 'flower-tag', color_id: 1 },
  'SLG-TAG31-OYS': { product: 'flower-tag', color_id: 2 },
  'SLG-TAG32-PLM': { product: 'flower-tag', color_id: 3 },
  'SLG-TAG35-RED': { product: 'flower-tag', color_id: 4 },

  'SLG-PASS01-BLA': { product: 'passport-holder', color_id: 0 },
  'SLG-PASS04-BRO': { product: 'passport-holder', color_id: 1 },
  'SLG-PASS02-OYS': { product: 'passport-holder', color_id: 2 },
  'SLG-PASS03-PLM': { product: 'passport-holder', color_id: 3 },

  'SLG-WLT10-BLA-L': { product: 'large-wallet', color_id: 0 },
  'SLG-WLT13-BRO-L': { product: 'large-wallet', color_id: 1 },
  'SLG-WLT11-OYS-L': { product: 'large-wallet', color_id: 2 },
  'SLG-WLT12-PLM-L': { product: 'large-wallet', color_id: 3 },
  'SLG-WLT14-RED-L': { product: 'large-wallet', color_id: 4 },

  'SLG-WLT01-BLA-S': { product: 'small-wallet', color_id: 0 },
  'SLG-WLT04-BRO-S': { product: 'small-wallet', color_id: 1 },
  'SLG-WLT02-OYS-S': { product: 'small-wallet', color_id: 2 },
  'SLG-WLT03-PLM-S': { product: 'small-wallet', color_id: 3 },

  // Compact Travel Wallet
  'SLG-WLT20-BLA-C': { product: 'compact-wallet', color_id: 0 },
  'SLG-WLT21-OYS-C': { product: 'compact-wallet', color_id: 1 },
  'SLG-WLT22-RED-C': { product: 'compact-wallet', color_id: 2 },

  // Luggage Tag Heart
  'SLG-TAG50-BLA': { product: 'heart-tag', color_id: 0 },
  'SLG-TAG51-OYS': { product: 'heart-tag', color_id: 1 },
  'SLG-TAG52-RED': { product: 'heart-tag', color_id: 2 },

  // Luggage Tag Bow
  'SLG-TAG60-BLA': { product: 'bow-tag', color_id: 0 },
  'SLG-TAG61-OYS': { product: 'bow-tag', color_id: 1 },
  'SLG-TAG62-RED': { product: 'bow-tag', color_id: 2 },

  // Laptop Sleeve
  'SLG-LAPTOP01-BLA': { product: 'laptop-sleeve', color_id: 0 },
  'SLG-LAPTOP02-OYS': { product: 'laptop-sleeve', color_id: 1 },
  'SLG-LAPTOP03-RED': { product: 'laptop-sleeve', color_id: 2 },

  // Keyrings flower
  'SLG-KEY10-BLA': { product: 'key-flower', color_id: 0 },
  'SLG-KEY11-OYS': { product: 'key-flower', color_id: 1 },
  'SLG-KEY12-RED': { product: 'key-flower', color_id: 2 },

  // Keyrings circle
  'SLG-KEY20-BLA': { product: 'key-circle', color_id: 0 },
  'SLG-KEY21-OYS': { product: 'key-circle', color_id: 1 },
  'SLG-KEY22-RED': { product: 'key-circle', color_id: 2 },

  // Keyrings hotel
  'SLG-KEY30-BLA': { product: 'key-hotel', color_id: 0 },
  'SLG-KEY31-OYS': { product: 'key-hotel', color_id: 1 },
  'SLG-KEY32-RED': { product: 'key-hotel', color_id: 2 },

  // Crescent Bag
  'EVE-CRES-81-NAT': { product: 'CrescentBag', color_id: 0 },
  'EVE-CRES-82-BLA': { product: 'CrescentBag', color_id: 1 },
  'EVE-CRES-83-BRO': { product: 'CrescentBag', color_id: 2 },
  'EVE-CRES-84-YEL': { product: 'CrescentBag', color_id: 3 },
  // Belt Bag
  'EVE-BELT-91-NAT': { product: 'BeltBag', color_id: 0 },
  'EVE-BELT-92-BLA': { product: 'BeltBag', color_id: 1 },
  'EVE-BELT-93-BRO': { product: 'BeltBag', color_id: 2 },
  'EVE-BELT-94-YEL': { product: 'BeltBag', color_id: 3 },

  'EVE-DUF-100-NAT': { product: 'eve-duf-small', color_id: 0 },
  'EVE-DUF-102-BRO': { product: 'eve-duf-small', color_id: 1 },
  'EVE-DUF-101-BLA': { product: 'eve-duf-small', color_id: 2 },

  'EVE-DUF-LAR-200-NAT': { product: 'eve-duf-large', color_id: 0 },
  'EVE-DUF-LAR-202-BRO': { product: 'eve-duf-large', color_id: 1 },
  'EVE-DUF-LAR-201-BLA': { product: 'eve-duf-large', color_id: 2 },

  // Juliette
  // Large Tote
  'JETTE-TOT-LRG01-BLA': { product: 'large-juliette', color_id: 0 },
  'JETTE-TOT-LRG03-BONE': { product: 'large-juliette', color_id: 1 },
  'JETTE-TOT-LRG02-BRO': { product: 'large-juliette', color_id: 2 },

  'JETTE-TOT2-LRG11-BLA': { product: 'large-juliette', color_id: 0 },
  'JETTE-TOT2-LRG12-BRO': { product: 'large-juliette', color_id: 2 },

  // Small Tote
  'JETTE-TOT-SML05-BLA': { product: 'small-juliette', color_id: 0 },
  'JETTE-TOT-SML06-BRO': { product: 'small-juliette', color_id: 1 },
  'JETTE-TOT-SML07-BONE': { product: 'small-juliette', color_id: 2 },
  'JETTE-TOT-SML08-GRN': { product: 'small-juliette', color_id: 3 },
  
  'JETTE-TOT2-SML21-BLA': { product: 'small-juliette', color_id: 0 },
  'JETTE-TOT2-SML22-BRO': { product: 'small-juliette', color_id: 1 },

  'COMAX31-CHA': { product: 'max', color_id: 0 },
  'COMAX32-FOR': { product: 'max', color_id: 1 },
  'COMAX36-DBRO': { product: 'max', color_id: 2 },
  'COMAX33-SHW': { product: 'max', color_id: 3 },
  'COMAX34-OLI': { product: 'max', color_id: 4 },
  'COMAX35-BPK': { product: 'max', color_id: 5 },

  'SET-EXPCHK-COMAX41-CHA': { product: 'CheckedExpandable', color_id: 0 },
  'SET-EXPCHK-COMAX42-FOR': { product: 'CheckedExpandable', color_id: 1 },
  'SET-EXPCHK-COMAX44-OLI': { product: 'CheckedExpandable', color_id: 2 },
  'SET-EXPCHK-COMAX43-SHW': { product: 'CheckedExpandable', color_id: 5 },
  'SET-EXPCHK-COMAX46-DBRO': { product: 'CheckedExpandable', color_id: 7 },

  'SET-EXPPLS-COMAX51-CHA': { product: 'PlusExpandable', color_id: 0 },
  'SET-EXPPLS-COMAX52-FOR': { product: 'PlusExpandable', color_id: 1 },

  'SET-EXPFMLY-COMAX51-CHA': { product: 'PlusExpandable', color_id: 0 },
  'SET-EXPFMLY-COMAX52-FOR': { product: 'PlusExpandable', color_id: 1 },
  'SET-EXPFMLY-COMAX54-OLI': { product: 'PlusExpandable', color_id: 2 },
  'SET-EXPFMLY-COMAX53-SHW': { product: 'PlusExpandable', color_id: 5 },
  'SET-EXPFMLY-COMAX56-DBRO': { product: 'PlusExpandable', color_id: 7 },

  'CAR-BPK21-LARGE-NAV': { product: 'Backpack', color_id: 3 },
  'CAR-BPK22-LARGE-BLA': { product: 'Backpack', color_id: 0 },

  // juliette-bucket
  'JETTE-TOT-BKT31-BLA': { product: 'juliette-bucket', color_id: 0 },
  'JETTE-TOT-BKT32-BRO': { product: 'juliette-bucket', color_id: 3 },
  'JETTE-TOT-BKT33-CHBR': { product: 'juliette-bucket', color_id: 1 },
  'JETTE-TOT-BKT34-CRM': { product: 'juliette-bucket', color_id: 2 },

  // juliette-large
  'JETTE-TOT2-LRG13-CHBR': { product: 'juliette-large', color_id: 2 },
  'JETTE-TOT2-LRG14-CRM': { product: 'juliette-large', color_id: 3 },
  'JETTE-TOT2-LRG15-LBLU': { product: 'juliette-large', color_id: 1 },

  // juliette-small
  'JETTE-TOT2-SML23-CHBR': { product: 'juliette-small', color_id: 2 },
  'JETTE-TOT2-SML24-CRM': { product: 'juliette-small', color_id: 3 },
  'JETTE-TOT2-SML25-LBLU': { product: 'juliette-small', color_id: 1 },

  // juliette-spacious
  'JETTE-TOT-XL51-BLA': { product: 'juliette-spacious', color_id: 0 },
  'JETTE-TOT-XL52-BRO': { product: 'juliette-spacious', color_id: 3 },
  'JETTE-TOT-XL53-CHBR': { product: 'juliette-spacious', color_id: 1 },
  'JETTE-TOT-XL54-CRM': { product: 'juliette-spacious', color_id: 2 },

  // juliette-work
  'JETTE-TOT-WRK41-BLA': { product: 'juliette-work', color_id: 0 },
  'JETTE-TOT-WRK42-BRO': { product: 'juliette-work', color_id: 3 },
  'JETTE-TOT-WRK43-CHBR': { product: 'juliette-work', color_id: 1 },
  'JETTE-TOT-WRK44-CRM': { product: 'juliette-work', color_id: 2 },
}
